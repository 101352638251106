
	frappe.templates['summary_card_item'] = `<div class="transaclist">
    <ul class="list-group list-group-flush">
        {% for (const value of data) { %}
        <li class="list-group-item">
            <a onclick="frappe.route_options={%= value['route_options'] %}; frappe.set_route({%= value['route'] %})" class="d-flex">
                <div class="avatar">
                <span
                    class="avatar-initial rounded-circle  text-grey"
                    >{%= format_number(value["count"], null, 0) %}</span
                >
                </div>
                <div class="pl-3">
                <p class="tx-medium mb-2">{%= doctype %}s</p>
                <p class="text-muted  mb-0">{%= value["workflow_state_label"] %}</p>
                </div>
                <div class="ml-auto text-right">
                <p class="mb-1">{%= format_number(value["amount"], null, 0) %}</p>
                <span class="indicator-pill {%= value['workflow_state_color'] %}">{%= value["workflow_state"] %}</span>
                </div>
            </a>
        </li>
		{% } %}
    </ul>
</div>`;
