import Widget from "./base_widget.js";
import "./summary_card_item.html"

frappe.provide("frappe.utils");

export default class SummaryCardWidget extends Widget {
	constructor(opts) {
		opts.shadow = true;
		super(opts);
		this.states = {
			'In-Progress' : 0,
			'Approved': 1,
			'Accounted': 2,
			'Processed': 3,
			'Rejected': 4
		};
	}

	get_config() {
		return {
			name: this.name,
			label: this.label,
			color: this.color,
			hidden: this.hidden,
		};
	}

	refresh() {
		this.set_body();
		this.set_footer()
	}

	set_body() {
		this.widget.addClass("summary-widget-box");
		this.make_card();
	}

	set_title() {
		$(this.title_field).html(`<div class="summary-label">${this.card_doc.label}</div>`);
	}

	make_card() {
		frappe.model.with_doc('Summary Card', this.name).then(card => {
			if (!card) {
				if (this.document_type) {
					frappe.run_serially([
						() => this.create_summary_card(),
						() => this.render_card(),
					]);
				} else {
					// widget doesn't exist so delete
					this.delete(false);
					return;
				}
			} else {
				this.card_doc = card;
				this.render_card();
			}

			this.set_events();
		});
	}

	create_summary_card() {
		this.set_doc_args();
		return frappe.xcall(
			'frappe.desk.doctype.summary_card.summary_card.create_summary_card',
			{
				'args': this.card_doc
			}
		).then(doc => {
			this.name = doc.name;
			this.card_doc = doc;
			this.widget.attr('data-widget-name', this.name);
		});
	}

	set_events() {
		$(this.body).click(() => {
			if (this.in_customize_mode || this.card_doc.type == 'Custom') return;
			this.set_route();
		});
	}

	set_route() {
		const name = this.card_doc.document_type ;
		const route = frappe.utils.generate_route({
			name: name,
			type: 'doctype',
			is_query_report: 0,
		});

		frappe.set_route(route);
	}

	set_doc_args() {
		this.card_doc = Object.assign({}, {
			document_type: this.document_type,
			label: this.label,
			function: this.function,
			aggregate_function_based_on: this.aggregate_function_based_on,
			color: this.color,
		});
	}

	get_settings(type) {
		this.filters = this.get_filters();
		return {
				method: 'finse.finse.doctype.summary_card.summary_card.get_result',
				args: {
					doc: this.card_doc,
					filters: this.filters,
				}
		};
	}

	get_filters() {
		const filters = frappe.dashboard_utils.get_all_filters(this.card_doc);
		return filters;
	}

	render_card() {
		this.prepare_actions();
		this.set_title();
		this.set_loading_state();

		this.settings = this.get_settings(this.card_doc.type);

		frappe.run_serially([
			() => this.render_body(),
		]);
	}

	set_loading_state() {
		$(this.body).html(`<div class="number-card-loading text-muted">
			${__('Loading...')}
		</div>`);
	}

	get_result() {
		return frappe.xcall(this.settings.method, this.settings.args);
	}

	set_footer() {
		this.footer.addClass('text-center')
		$(this.footer).html(`<a class="link-03 text-primary">View All <i class="bi bi-arrow-right ml-2"/></a>`);
	}


	render_body() {
		let doctype = this.card_doc.document_type;

		return this.get_result().then(async (res) => {

		

			for (let result of res) {
				result.route = "['List', '" + this.card_doc.document_type + "',]";
				result.route_options = "{workflow_state:'" + result.workflow_state + "'}";
			}
			return res.sort((a, b) => this.states[a.workflow_state] - this.states[b.workflow_state]);
		})
		.then((res) => {
			$(this.widget).parent().addClass('widgetcard')
			$(this.body).html(
				`<div class="">
					${
						frappe.render_template('summary_card_item', {
							data: res,
							doctype
						})
					}
				</div>
				`
			);
		});
	}

	make_widget() {
		const body = $(`

				<div class="col-lg-4 col-sm-12 col-12">
					<div class="widget cardnewdash shadow-com" data-widget-name="${this.name ? this.name : ''}">
						<div class="widget-head">
							<div class="widget-label">
								<div class="widget-title"></div>
								<div class="widget-subtitle"></div>
							</div>
							<div class="widget-control"></div>
						</div>
						<div class="widget-body"></div>
						<div class="widget-footer"></div>
					</div>
				</div>
		`);

		if (this.index % 3 == 0) {
			this.widget = $(`<div class="carousel-item  ${this.index == 0 ? 'active' : ''}"><div class="row widgetcard"></div></div>`)
		} else {
			this.widget = this.widgets_list[this.widgets_list.length - 1].widget;
		}

		this.title_field = body.find(".widget-title");
		this.subtitle_field = body.find(".widget-subtitle");
		this.body = body.find(".widget-body");
		this.action_area = body.find(".widget-control");
		this.head = body.find(".widget-head");
		this.footer = body.find(".widget-footer");

		body.appendTo(this.widget.find('.row'));
		this.refresh();
	}

	prepare_actions() {
		let actions = [
			{
				label: __('Refresh'),
				action: 'action-refresh',
				handler: () => {
					this.render_card();
				}
			},
			{
				label: __('Edit'),
				action: 'action-edit',
				handler: () => {
					frappe.set_route(
						'Form',
						'Summary Card',
						this.name
					);
				}
			},
		];

		this.set_card_actions(actions);
	}

	set_card_actions(actions) {
		/* eslint-disable indent */
		this.card_actions =
			$(`<div class="card-actions dropdown pull-right">
				<a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				...
				</a>
				<ul class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
					${actions
						.map(
							action =>
								`<li class="dropdown-item">
									<a data-action="${action.action}">${action.label}</a>
								</li>`
						).join('')}
				</ul>
			</div>`);
		/* eslint-disable indent */

		this.card_actions.find("a[data-action]").each((i, o) => {
			const action = o.dataset.action;
			$(o).click(actions.find(a => a.action === action));
		});

		this.action_area.html(this.card_actions);
	}
}
